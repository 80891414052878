import { render, staticRenderFns } from "./orderFlow.vue?vue&type=template&id=38402336&scoped=true&"
import script from "./orderFlow.vue?vue&type=script&lang=js&"
export * from "./orderFlow.vue?vue&type=script&lang=js&"
import style0 from "./orderFlow.vue?vue&type=style&index=0&id=38402336&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38402336",
  null
  
)

export default component.exports